import React from 'react';
import Banner from './Banner';
import Footer from './Footer';

function PrivacyPolicy() {
  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      <div className="flex-grow bg-gray-50">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              This Privacy Policy is issued by EasyApp ("EasyApp", "we", "us", or "our") and applies to individuals outside our organization with whom we interact, including Zendesk administrators, consultants, end-users of our applications, visitors to our website at easyappforz.com, customers of our services, and potential clients (collectively, "you"). Please note that specific sections of this Policy may apply differently depending on your relationship with EasyApp and how you interact with our services.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              As developers of no-code solutions for Zendesk, we are committed to maintaining the highest standards of data protection while providing innovative tools that enhance your Zendesk experience. This Privacy Policy outlines our practices regarding the collection, use, and protection of your information when you use our EasyApp for Zendesk application ("Application") or visit our website ("Website").
            </p>
            <p className="text-gray-700 leading-relaxed">
              Our commitment to transparency means that we want you to fully understand how we handle your data. We've designed our services with privacy in mind, ensuring that we collect only the minimum necessary information required to provide you with our no-code application building tools. If you have any questions about this Privacy Policy or our privacy practices, please reach out to us at contacto@easyappforz.com before using the Application.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
            <p className="text-gray-700 leading-relaxed mb-4">We collect and process only the minimum necessary information to provide our services:</p>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">Your Zendesk subdomain for authentication purposes</li>
              <li className="mb-2">Application configurations set by users within Zendesk</li>
              <li className="mb-2">Basic usage statistics to improve our service</li>
              <li className="mb-2">Payment information (processed securely through our payment provider)</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
            <p className="text-gray-700 leading-relaxed mb-4">The information we collect is used exclusively for:</p>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">Providing and maintaining our Application</li>
              <li className="mb-2">Processing your payments</li>
              <li className="mb-2">Sending essential service updates</li>
              <li className="mb-2">Improving our Application based on usage patterns</li>
              <li className="mb-2">Responding to your support requests</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              We implement robust security measures to protect your information:
            </p>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">All data is encrypted at rest and in transit</li>
              <li className="mb-2">We maintain strict access controls to your data</li>
              <li className="mb-2">Regular security audits and updates</li>
              <li className="mb-2">No storage of sensitive Zendesk instance data</li>
              <li className="mb-2">Secure API communication protocols</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
            <p className="text-gray-700 leading-relaxed">
              We retain your information only for as long as necessary to provide our services. When you cancel your subscription, we will delete your data within 30 days, except where we are required to maintain records for legal or accounting purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
            <p className="text-gray-700 leading-relaxed mb-4">You have the right to:</p>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">Access your personal data</li>
              <li className="mb-2">Correct inaccurate data</li>
              <li className="mb-2">Request deletion of your data</li>
              <li className="mb-2">Export your data in a machine-readable format</li>
              <li className="mb-2">Withdraw consent for data processing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
            <p className="text-gray-700 leading-relaxed">
              We use select third-party services for payment processing and application hosting. These providers are carefully selected and bound by strict data protection agreements. We do not share your data with any other third parties for marketing or other purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Cookies and Tracking</h2>
            <p className="text-gray-700 leading-relaxed">
              We use essential cookies necessary for the operation of our Application. These cookies do not track your browsing habits or collect personal information beyond what is necessary for the Application to function.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Children's Privacy</h2>
            <p className="text-gray-700 leading-relaxed">
              Our services are not directed to individuals under the age of 16. We do not knowingly collect personal information from children under 16.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Changes to This Policy</h2>
            <p className="text-gray-700 leading-relaxed">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date. Significant changes will be communicated directly to users.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-700 leading-relaxed">
              If you have any questions about this Privacy Policy or our data practices, please contact us at:
              <br />
              Email: <a href="mailto:contacto@easyappforz.com" className="text-blue-600 hover:underline">contacto@easyappforz.com</a>
            </p>
          </section>

          <p className="text-gray-600 italic mt-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy; 