function VideoDosAndTextSection({ scrollToForm }) {
  return (
    <section className="w-full flex flex-col md:flex-row py-6">
      <div className="w-full md:w-1/2 my-5">
        <img
          src="/easyappforzendeskgif01.gif"
          alt="Easyappforz apps for zendesk"
          className="w-full"
          style={{ height: '500px', objectFit: 'contain' }}
        />
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center px-14">
        <div className="text-left">
          <p className="text-4xl font-semibold mr-16">Use Cases</p>
          <p className="text-lg my-4 text-gray-700 mr-24">
            Use your own APIs and display relevant information for the Agent in the Ticket:
          </p>
          <ul className="list-none space-y-4 mt-6">
            <li className="flex items-center">
              <img src="/user.png" alt="icon" className="w-6 h-6 mr-2"/>
              <span>Your Customer Data (CRM)</span>
            </li>
            <li className="flex items-center">
              <img src="/orders.png" alt="icon" className="w-6 h-6 mr-2"/>
              <span>Check payment orders, charges, etc.</span>
            </li>
            <li className="flex items-center">
              <img src="/tracking.png" alt="icon" className="w-6 h-6 mr-2"/>
              <span>Order status and tracking</span>
            </li>
            <li className="flex items-center">
              <img src="/knowledge.png" alt="icon" className="w-6 h-6 mr-2"/>
              <span>Articles from your external knowledge base</span>
            </li>
            <li className="flex items-center">
              <img src="/ticket.png" alt="icon" className="w-6 h-6 mr-2"/>
              <span>Get data from your API using Ticket attributes</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default VideoDosAndTextSection;
