import { useNavigate } from 'react-router-dom';
import Header from './Banner';
import Footer from './Footer';
import './Plans.css';

function Plans() {
  const navigate = useNavigate();

  const plans = [
    {
      priceId: "pri_01jjdgf8gszgth362v0y1zambn",
      name: "ticket time sandbox",
      price: "$3.50",
      description: [
        "Track and display time spent on tickets by agents",
        "Total AHT capture (with or without ticket saving)",
        "Real-time and historical timer",
        "Alert rules by ticket types and groups",
        "Detailed breakdown on the ticket"
      ]
    },
    {
      priceId: "pri_01jjg2t6d7xqsrt6183yda0gft",
      name: "EasyApp for Zendesk",
      price: "$9.99",
      description: [
        "EasyApp builder, to create your own calls to external API's.",
        "Fully customizable API integration",
        "Connection to native and custom ticket fields",
        "Custom design of your API response in the ticket",
        "Creating, editing and deleting API call flows"
      ]
    }
  ];

  const handleSubscribe = (priceId) => {
    navigate('/subscription', { state: { priceId } });
  };

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Our Zendesk Applications
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Made by admins, for admins.
            </p>
          </div>

          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto">
            {plans.map((plan) => (
              <div key={plan.priceId} className="bg-white rounded-lg shadow-lg divide-y divide-gray-200">
                <div className="p-6">
                  <h2 className="text-2xl leading-6 font-semibold text-gray-900">
                    {plan.name}
                  </h2>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      {plan.price}
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      /agent/month
                    </span>
                  </p>
                  <button
                    onClick={() => handleSubscribe(plan.priceId)}
                    className="mt-8 w-full bg-[rgb(15,38,69)] border border-transparent rounded-md py-3 px-5 text-base font-medium text-white hover:bg-[rgb(28,56,97)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[rgb(15,38,69)] transition-colors duration-200"
                  >
                    Subscribe Now
                  </button>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {plan.description.map((feature, featureIndex) => (
                      <li key={featureIndex} className="text-base text-gray-500">
                        • {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Plans; 