import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const [isOpenQuienesSomos, setIsOpenQuienesSomos] = useState(false);
  const [isOpenLoQueOfrecemos, setIsOpenLoQueOfrecemos] = useState(false);
  const [isOpenPreguntasFrecuentes, setIsOpenPreguntasFrecuentes] = useState(false);

  return (
    <footer className="bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link to="/" className="text-base text-gray-300 hover:text-white">
              Home
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/plans" className="text-base text-gray-300 hover:text-white">
              Pricing
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/terms-and-conditions" className="text-base text-gray-300 hover:text-white">
              Terms and Conditions
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/privacy-policy" className="text-base text-gray-300 hover:text-white">
              Privacy Policy
            </Link>
          </div>
          <div className="px-5 py-2">
            <button 
              onClick={() => setIsOpenQuienesSomos(true)} 
              className="text-base text-gray-300 hover:text-white"
            >
              About Us
            </button>
          </div>
          <div className="px-5 py-2">
            <button 
              onClick={() => setIsOpenLoQueOfrecemos(true)} 
              className="text-base text-gray-300 hover:text-white"
            >
              What We Offer
            </button>
          </div>
          <div className="px-5 py-2">
            <button 
              onClick={() => setIsOpenPreguntasFrecuentes(true)} 
              className="text-base text-gray-300 hover:text-white"
            >
              FAQ
            </button>
          </div>
        </nav>
      </div>

      {/* About Us Modal */}
      {isOpenQuienesSomos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">About Us</h2>
            <p>
              We are a team with over 15 years of experience in CRM, Zendesk, Salesforce, Web Development, Cloud Architecture, CX, and general consulting. Throughout our career, we have executed CRM implementation projects and web applications for companies across different industries, as well as working on cloud telephony solutions, process optimization, and customer-centric experience design.
              <br></br><br></br>
              With more than 20 successfully implemented projects, we have decided to develop a new way of creating applications for Zendesk, which is especially useful for administrators and consultants of this platform, as it makes work simpler but effective by integrating and presenting information from external systems or APIs to agents within the Zendesk ticket.
            </p>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenQuienesSomos(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* What We Offer Modal */}
      {isOpenLoQueOfrecemos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">What We Offer</h2>
            <p>
              EasyApp is an innovative solution that allows you to implement Zendesk applications in just minutes, without requiring programming knowledge. You only need a basic understanding of how to make API calls, and our tool will guide you step by step to deploy an application alongside the ticket. This way, agents can view the information you've previously configured using your API.
            </p><br/>
            <p>EasyApp is simple, versatile, and fast. Additionally, it ensures your data security: all information remains in your own Zendesk instance, without being stored in external databases.</p>
            <br/>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenLoQueOfrecemos(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* FAQ Modal */}
      {isOpenPreguntasFrecuentes && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-4xl w-full overflow-y-auto p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Frequently Asked Questions</h2>
            <ul className="list-disc pl-5">
              <li><strong>Do I have to pay anything for early access?</strong> No, just complete the form and we'll contact you to give you access.</li><br/>
              <li><strong>Is my API configuration data stored in an external database?</strong> No, all your data is stored in Zendesk, we don't connect or send your information to external services.</li><br/>
              <li><strong>Can I create any type of application?</strong> Currently, you can only create applications that call APIs and display information in the Ticket for your agent to view.</li><br/>
              <li><strong>Do I have to write code at any point?</strong> No, EasyApp is designed to be a No-Code tool and easy to use for Zendesk Administrators and Consultants.</li><br/>
              <li><strong>Can I install it in Sandbox?</strong> Yes, you can install it in any Zendesk instance.<a href="https://support.zendesk.com/hc/en-us/articles/4408893293466-About-Zendesk-Support-plan-types" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.</li><br/>
              <li><strong>If I want something more customized, do you do that?</strong> Currently, we only focus on the development and evolution of EasyApp. If what you need is associated with this app, yes, we can help you.</li><br/>
              <li><strong>Do you implement Zendesk?</strong> Yes, we are certified Zendesk Administrators and developers.</li><br/>
              <li><strong>Can this app be installed in systems other than Zendesk?</strong> No, it's only available for Zendesk.</li><br/>
              <li><strong>Do I need to add my credit card for Early Access?</strong> No, you just complete the form and we'll get in touch with you.</li><br/>
              <li><strong>When will it be available on the Zendesk Marketplace?</strong> The estimated public release date on the Marketplace is set for late October 2024.</li><br/>
              <li><strong>If I have questions, who can I write to?</strong> Send us an email at contacto@easyappforz.com and we'll be happy to address your questions.</li><br/>
            </ul>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenPreguntasFrecuentes(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;