import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Pricing.css';
import Header from './Banner';
import Footer from './Footer';

function Subscription() {
  const navigate = useNavigate();
  const location = useLocation();
  const [quantity, setQuantity] = useState(1);
  const [paddleInitialized, setPaddleInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [items, setItems] = useState([]);
  const [totals, setTotals] = useState({
    oneTime: 0,
    recurring: 0,
    discount: 0,
    tax: 0,
    total: 0
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [trialDetails, setTrialDetails] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);
  const [quantityLimits, setQuantityLimits] = useState({ min: 1, max: 500 });
  const [currentQuantity, setCurrentQuantity] = useState(1);
  
  const priceId = location.state?.priceId || "pri_01jjdgf8gszgth362v0y1zambn";

  const updateTable = (event) => {
    console.log('Event received:', event);
    if (!event.name) return;

    if (event.name === 'checkout.loaded' || event.name === 'preview.loaded') {
      const eventData = event.data;
      console.log('Raw event data:', eventData);

      if (eventData.details?.lineItems) {
        const processedItems = eventData.details.lineItems.map(lineItem => ({
          product: {
            name: lineItem.product.name
          },
          price_name: lineItem.price.billingCycle?.interval || 'one-time',
          quantity: lineItem.quantity,
          recurring_totals: {
            subtotal: parseFloat(lineItem.totals.subtotal)
          },
          formatted_totals: lineItem.formattedTotals
        }));
        
        console.log('Processed items:', processedItems);
        setItems(processedItems);

        // Calculamos los totales basados en el primer item
        const firstItem = eventData.details.lineItems[0];
        setTotals({
          oneTime: parseFloat(firstItem.totals?.subtotal || '0'),
          recurring: parseFloat(firstItem.recurring_totals?.subtotal || '0'),
          discount: parseFloat(firstItem.totals?.discount || '0'),
          tax: parseFloat(firstItem.totals?.tax || '0'),
          total: parseFloat(firstItem.totals?.total || '0')
        });
      }
    }
  };

  // Inicialización de Paddle
  useEffect(() => {
    if (window.Paddle) {
      window.Paddle.Environment.set('sandbox');
      window.Paddle.Initialize({ 
        token: 'test_1a3ea771578843e66e6e1930aab',
        eventCallback: function(data) {
          if (data.name === "checkout.completed") {
            console.log('Checkout completed:', data);
            setShowSuccessModal(true);
          }
        }
      });
      setPaddleInitialized(true);
    }
  }, []);

  const handleQuantityInput = (newQuantity) => {
    if (newQuantity < quantityLimits.min || newQuantity > quantityLimits.max) return;
    setCurrentQuantity(newQuantity);
  };

  const handleCalculate = () => {
    handleQuantityChange(currentQuantity);
  };

  const handleQuantityChange = async (newQuantity) => {
    if (newQuantity < quantityLimits.min || newQuantity > quantityLimits.max) return;
    setIsLoading(true);
    setQuantity(newQuantity);
    
    try {
      const priceRequest = {
        items: [{
          priceId: priceId,
          quantity: newQuantity
        }]
      };

      const pricePreview = await window.Paddle.PricePreview(priceRequest);
      const item = pricePreview?.data?.details?.lineItems?.[0];
      
      console.log('Price preview response:', item); // Para debug
      
      // Obtener límites de cantidad
      if (item?.quantity) {
        setQuantityLimits({
          min: item.quantity.minimum || 1,
          max: item.quantity.maximum || 500
        });
      }

      // Obtener detalles del trial
      const trialInfo = item?.price?.trialPeriod;
      if (trialInfo) {
        setTrialDetails({
          interval: trialInfo.interval,
          frequency: trialInfo.frequency
        });
      }

      // Obtener detalles del precio con validación adicional
      if (item) {
        // Asegurarnos de que estamos obteniendo los valores correctos
        const rawTotal = item.formattedTotals?.total || '0';
        const rawUnitPrice = item.formattedUnitTotals?.total || '0';
        
        // Limpiar y parsear los valores
        const totalPrice = parseFloat(rawTotal.replace(/[^0-9.-]+/g, ''));
        const unitPrice = parseFloat(rawUnitPrice.replace(/[^0-9.-]+/g, ''));
        
        setPriceDetails({
          unitPrice: unitPrice,
          total: totalPrice,
          productName: item.product?.name || 'Subscription'
        });
      }

      const checkoutOptions = {
        items: [{
          priceId: priceId,
          quantity: newQuantity
        }],
        settings: {
          displayMode: 'inline',
          frameTarget: 'checkout-container',
          frameInitialHeight: 450,
          frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;',
          locale: 'en',
          theme: 'light',
          allowedPaymentMethods: ['card', 'paypal']
        }
      };

      await window.Paddle.Checkout.open(checkoutOptions);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Manejo del checkout inicial
  useEffect(() => {
    if (!paddleInitialized) {
      console.log('Waiting for Paddle initialization...');
      return;
    }

    handleQuantityChange(quantity);
  }, [paddleInitialized]);

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/');
  };

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-50">
        <div className="page-container max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Subscribe to {priceDetails?.productName || 'EasyApp'}
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div 
                id="checkout-container"
                className="checkout-container"
                style={{ 
                  minHeight: '450px',
                  width: '100%',
                  minWidth: '312px'
                }}
              />
            </div>

            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Order Summary</h3>
              
              <div className="mb-6">
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-2">
                  Number of agents
                </label>
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => handleQuantityInput(currentQuantity - 1)}
                    disabled={currentQuantity <= quantityLimits.min || isLoading}
                    className="p-2 rounded-md border border-gray-300 hover:bg-gray-50 disabled:opacity-50"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantity"
                    value={currentQuantity}
                    onChange={(e) => handleQuantityInput(parseInt(e.target.value) || quantityLimits.min)}
                    min={quantityLimits.min}
                    max={quantityLimits.max}
                    className="block w-20 text-center rounded-md border-gray-300 shadow-sm focus:border-[#7C3AED] focus:ring-[#7C3AED] sm:text-sm"
                  />
                  <button
                    onClick={() => handleQuantityInput(currentQuantity + 1)}
                    disabled={currentQuantity >= quantityLimits.max || isLoading}
                    className="p-2 rounded-md border border-gray-300 hover:bg-gray-50 disabled:opacity-50"
                  >
                    +
                  </button>
                  <button
                    onClick={handleCalculate}
                    disabled={isLoading}
                    className="ml-4 px-4 py-2 bg-[rgb(15,38,69)] text-white rounded-md hover:bg-[rgb(28,56,97)] focus:outline-none focus:ring-2 focus:ring-[rgb(15,38,69)] focus:ring-offset-2 disabled:opacity-50"
                  >
                    Calculate
                  </button>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Min: {quantityLimits.min} agent, Max: {quantityLimits.max} agents
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex justify-between">
                  <div>
                    <p className="font-medium">{priceDetails?.productName}</p>
                    <p className="text-sm text-gray-500">Monthly subscription</p>
                  </div>
                  <div className="text-right">
                    <p>Quantity: {quantity}</p>
                    <p>${((priceDetails?.total || 0)).toFixed(2)}/month</p>
                  </div>
                </div>
              </div>

              <div className="mt-6 border-t pt-4">
                <h4 className="font-medium mb-2">Payment Details</h4>
                <div className="space-y-2">
                  {trialDetails && (
                    <div className="flex justify-between text-sm">
                      <span>First {trialDetails.frequency} {trialDetails.interval}{trialDetails.frequency > 1 ? 's' : ''}</span>
                      <span className="font-medium text-green-600">Free Trial</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span>After trial period</span>
                    <span>${(priceDetails?.total || 0).toFixed(2)}/month</span>
                  </div>
                  {quantity > 1 && (
                    <div className="text-sm text-gray-500 mt-1">
                      ${(priceDetails?.unitPrice || 0).toFixed(2)} per agent/month
                    </div>
                  )}
                </div>
                {trialDetails && (
                  <div className="mt-4 p-3 bg-blue-50 rounded-md">
                    <p className="text-sm text-blue-700">
                      Your subscription will start with a {trialDetails.frequency}-{trialDetails.interval} free trial. 
                      You won't be charged until the trial ends, and you can cancel anytime.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {/* Modal de éxito */}
        {showSuccessModal && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                  <svg
                    className="h-6 w-6 text-green-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                  Thank you for your purchase!
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You have successfully subscribed. Your application will be enabled in Zendesk within a few minutes. 
                    If you haven't installed it yet, you can do so now.
                  </p>
                </div>
                <div className="mt-6">
                  <button
                    onClick={handleModalClose}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Got it
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Subscription;
