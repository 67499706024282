import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function FormSuscribe() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
    .sendForm('service_v0wievl', 'template_iv613uo', form.current, {
      publicKey: 'nwEMPU_WXjR95gpl_',
    })
    .then(
      () => {
        setModalMessage("Early access request successfully submitted");
        setIsModalOpen(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setModalMessage("Something went wrong, please try again later");
        setIsModalOpen(true);
        setIsLoading(false);
      });
      e.target.reset();
  };

  return (
    <section className="w-full flex flex-col md:flex-row py-8">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start pr-14">
        <h2 className="text-4xl font-semibold mb-4">Be among the first to get it</h2>
        <p className="text-lg text-gray-700 mb-4">
          EasyApp is available in Early Access. We want to offer you the best low-code experience, request your access through the form.
        </p>
        <p className="text-lg text-gray-700">Talk to you soon!</p>
      </div>
      <div className="w-full md:w-1/2 flex justify-center items-center">
        <form ref={form} onSubmit={sendEmail} className="w-full max-w-md">
          <div className="mb-4">
            <label htmlFor="user_name" className="block text-gray-700 text-sm font-bold mb-2">Full Name *</label>
            <input
              type="text"
              name="user_name"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="user_email" className="block text-gray-700 text-sm font-bold mb-2">Email *</label>
            <input
              type="email"
              name="user_email"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="company" className="block text-gray-700 text-sm font-bold mb-2">Company *</label>
            <input
              type="text"
              name="company"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message (Optional)</label>
            <textarea
              name="message"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              disabled={isLoading}
              className={`bg-[#ffa100] hover:bg-[#ff9100] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? 'Sending...' : 'Request Early Access'}
            </button>
          </div>
        </form>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <p className="text-xl mb-4">{modalMessage}</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-[#ffa100] text-white py-2 px-4 rounded hover:bg-[#ff9100]"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default FormSuscribe;
